import { customElement, property, state } from 'lit/decorators.js';
import { style } from './sitemap-column.styles';
import {html, LitElement} from 'lit';


@customElement('sbk-sitemap-column')
export class SbkSitemapColumn extends LitElement {

    @property()
    header = '';

    @state()
    _expanded = false;

    static get styles() {
        return style;
    }

    _toggle() {
        this._expanded = !this._expanded
    }

    render() {
        return html`
            <dl>
                <dt>
                    <button id="accordion-header"
                            class="sitemap-column-header sitemap-column-header-mobile"
                            @click=${this._toggle}
                            aria-controls="sitemap-panel"
                            aria-expanded=${this._expanded}>
                        ${this.header}
                        <sbk-icon class="sitemap-column-icon" size="24" symbol=${this._expanded ? 'chevron-up' : 'chevron-down'}></sbk-icon>
                    </button>
                     <p class="sitemap-column-header sitemap-column-header-desktop">
                        ${this.header}
                    </p>
                </dt>
                <dd id="sitemap-panel"
                    aria-labelledby="accordion-header"
                    ?hidden=${!this._expanded}>
                    <ul>
                        <slot></slot>
                    </ul>
                </dd>
            </dl>
        `;
    }
}
